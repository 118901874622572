import './App.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useEffect, useState, useCallback} from 'react';
import { ChainArr } from "./contracts/ContractVars";
import Home from "./components/HomeComponent";
import ViewCats from "./components/ViewCatsComponent";
import CoinCollection from "./components/CoinCollectionComponent";
import MarketPlace from "./components/MarketPlaceComponent";
import Profile from "./components/ProfileComponent";
import ViewCat from "./components/ViewCatComponent";
import Login from "./components/LoginComponent";
import Register from "./components/RegisterComponent";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toastify from "./components/ToastifyComponent";
import { ApiRepos } from './contracts/ContractVars';

function App() {
    const initialAuthState = localStorage.getItem('isAuthenticated') === 'true';
    const initialUserName = localStorage.getItem('userName');

    const [currentAccount, setCurrentAccount] = useState(null);
    const [currentChain, setCurrentChain] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(initialAuthState);
    const [userName, setUserName] = useState(initialUserName);

    const showNotification = (message, type = 'info') => {
        switch (type) {
            case 'error':
                toast.error(message);
                break;
            case 'success':
                toast.success(message);
                break;
            default:
                toast(message);
        }
    };

    const protectedRoute = (Component, props = {}) => {
        return isAuthenticated ? <Component {...props} /> : <Login onLoginSuccess={handleLoginSuccess} />;
    }

    const handleLoginSuccess = (data) => {
        setIsAuthenticated(true);
        setUserName(data.userName);
        localStorage.setItem('userName', data.userName);
        localStorage.setItem('isAuthenticated', 'true');
    };

    const updateWallet = async (ethereum, accounts) => {
        if (accounts.length !== 0) {
            setCurrentAccount(accounts[0]);
            const chainId = ethereum.chainId;
            const ChainData = ChainArr.find(({ id }) => id === chainId);
            if (ChainData) {
                setCurrentChain(ChainData.value);
            } else {
                console.log("error - can't lookup network");
            }

            ethereum.on('accountsChanged', (accounts) => {
                window.location.reload();
            });

            ethereum.on('chainChanged', (chainId) => {
                window.location.reload();
            });
        }
    }

    const checkWalletIsConnected = useCallback(async () => {
        const { ethereum } = window;

        if (!ethereum) {
            showNotification('Please install Metamask to use this service!', 'error');
            return;
        }

        const accounts = await ethereum.request({ method: 'eth_accounts' });
        updateWallet(ethereum, accounts);
    }, []);

    const handleConnectWallet = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            showNotification("Please install Metamask to use this service!", 'error');
            return;
        }

        try {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            updateWallet(ethereum, accounts);
        } catch (err) {
            console.error(err);
            showNotification('Error connecting to wallet!', 'error');
        }
    }

    const handleRegistrationSuccess = (data) => {
        showNotification("Registration Successful!", 'success');

        setIsAuthenticated(true);
        setUserName(data.userName);
        localStorage.setItem('userName', data.userName);
        localStorage.setItem('isAuthenticated', 'true');
    };

    const handleSignOutAccount = () => {
        fetch(`${ApiRepos}account/logout`, {
            method: 'GET'
        })
            .then(response => {
                if (response.ok) {
                    setIsAuthenticated(false);
                    setUserName(null);
                    localStorage.removeItem('userName');
                    localStorage.removeItem('isAuthenticated');
                } else {
                    console.error("Logout failed. Status:", response.status);
                    return response.text();
                }
            })
            .then(text => {
                if (text) { // Only log if there's a message to log
                    console.error(`Message from server: ${text}`);
                }
            })
            .catch(error => {
                console.error("An error occurred during the logout process:", error);
            });
    }

    const handleDisconnectWallet = () => {
        setCurrentAccount(null);
        setCurrentChain(null);

        toast.info("This won't properly disconnect, please disconnect via MetaMask!");
    }

    const connectedWalletAddress = () => {
        const str1 = currentAccount;
        return <span>Connected Wallet: {str1.substring(0, 6)}...{str1.substring(str1.length - 4)}<br />{currentChain}</span>
    }

    const connectedAccountAddress = () => {
        const str1 = userName;
        return <span>Connected Account: <br /> {str1}</span>
    }

    const UserInfo = () => {
        if (isAuthenticated) {
            return (
                <div className="user-info">
                    {connectedAccountAddress()}
                    <button onClick={handleSignOutAccount}>Sign Out Account</button>
                </div>
            );
        }
    };

    const WalletInfo = () => {
        if (currentAccount) {
            return (
                <div className="wallet-info">
                    {connectedWalletAddress()}
                    <br />
                    <button onClick={handleDisconnectWallet}>Disconnect Wallet</button>
                </div>
            );
        } else {
            return (
                <button onClick={handleConnectWallet} className='cta-button connect-wallet-button'>
                    Connect Wallet
                </button>
            )
        }
    };

    useEffect(() => {
        let isMounted = true;  // to track if component is still mounted

        checkWalletIsConnected();

        const { ethereum } = window;

        const handleAccountsChanged = (accounts) => {
            if (isMounted) {
                window.location.reload();
            }
        };

        const handleChainChanged = (chainId) => {
            if (isMounted) {
                window.location.reload();
            }
        };

        if (ethereum) {
            ethereum.on('accountsChanged', handleAccountsChanged);
            ethereum.on('chainChanged', handleChainChanged);
        }

        return () => {
            isMounted = false;
            // Since you can't remove listeners using `ethereum.off`, 
            // you've implemented the checks in the listeners themselves.
        };
    }, [checkWalletIsConnected]);

    return (
        <Router>
            <div className='main-app'>
                <Navbar bg="black" variant="dark" expand="lg">
                    <Container fluid>
                        <Navbar.Brand as={Link} to="/"><img alt="I'm a kitty cat NFT" src='../logo.png' /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="/">Home</Nav.Link>
                                <Nav.Link as={Link} to="/viewcats">View Cats</Nav.Link>
                                <Nav.Link as={Link} to="/coincollection">Coin Collection</Nav.Link>
                                <Nav.Link as={Link} to="/marketplace">Marketplace</Nav.Link>
                                <Nav.Link as={Link} to="/profile">Profile</Nav.Link>
                            </Nav>
                            <WalletInfo />
                            <UserInfo />
                        </Navbar.Collapse>                        
                    </Container>
                </Navbar>
                <Routes>
                    <Route path="/" element={protectedRoute(Home, { /* any props you want to pass to Home */ })} />
                    <Route path="/viewcats" element={<ViewCats currentAccount={currentAccount} currentChain={currentChain} />} />
                    <Route path="/viewcat/:id" element={<ViewCat />} />
                    <Route path="/coincollection" element={<CoinCollection />} />
                    <Route path="/marketplace" element={<MarketPlace />} />
                    <Route path="/profile" element={protectedRoute(Profile, { currentAccount })} />
                    <Route path="/register" element={<Register onRegisterSuccess={handleRegistrationSuccess} />} />
                </Routes>
                <Toastify />
            </div>
        </Router>
    );
}

export default App;