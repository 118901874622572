const Home = () => {

    return (
        <div className="home">
            <br />
            <h1>Welcome to the I'm a Kitty Cat App Site</h1>

        </div>
    );
}

export default Home;