import React, { useState, useEffect } from 'react';
import { ApiRepos } from '../contracts/ContractVars';

const Profile = ({ currentAccount }) => {
    //console.log("Profile rendered");
    const [userName, setUserName] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [walletAddress, setWalletAddress] = useState(null);
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        fetch(`${ApiRepos}user/profile`, { credentials: 'include' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUserName(data.userName || "");
                setWalletAddress(data.walletAddress || "");
                setFullName(data.fullName || "");
            })
            .catch(error => {
                console.error('Error fetching user profile:', error);
            });
    }, []);

    const removeWallet = async () => {
        setWalletAddress('');
        try {
            const response = await fetch(`${ApiRepos}wallet/unlinkwallet`, {
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({ username: userName }),
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.ok) {
                //console.log('Wallet removed!');
            } else {
                const data = await response.json();
                console.error('Error removing wallet:', data.error);
            }

        } catch (error) {
            console.error('Network error or unable to connect to the server. Please try again:', error);
        }
    }

    const linkWallet = async () => {
        if (!currentAccount) return;

        try {
            const response = await fetch(`${ApiRepos}wallet/linkwallet`, {
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({
                    Username: userName,
                    WalletAddress: currentAccount
                }),
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.ok) {
                //console.log('Wallet linked successfully!');
                setWalletAddress(currentAccount);
            } else {
                const data = await response.json();
                console.error('Error linking wallet:', data.error);
            }

        } catch (error) {
            console.error('Network error or unable to connect to the server. Please try again:', error);
        }
    }

    const updateFullName = async () => {
        try {
            const response = await fetch(`${ApiRepos}user/updateFullName`, {
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({ FullName: fullName }),
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.ok) {
                //console.log('Full name updated successfully!');
                setNotification("Full name updated successfully!");
            } else {
                const data = await response.json();
                console.error('Error updating full name:', data.error);
                setNotification("Error updating full name. Please try again.");
            }
        } catch (error) {
            console.error('Network error or unable to connect to the server. Please try again:', error);
            setNotification("Network error or unable to connect to the server. Please try again.");
        }
    }



    return (
        <div className="profile">
            <h1>Profile</h1>
            {notification && (
                <div className="notification" style={{
                    margin: '10px 0',
                    padding: '10px',
                    border: '1px solid green',
                    backgroundColor: '#e6ffe6'
                }}>
                    {notification}
                </div>
            )}
            <div>
                <label>User Name:</label>
                <p>{userName || ""}</p>
            </div>
            <div>
                <label>Full Name:</label>
                <input type="text" value={fullName || ""} onChange={(e) => setFullName(e.target.value)} />
                <button onClick={updateFullName}>Update Full Name</button>
                {notification && <div className="notification">{notification}</div>}
            </div>            
            <h2>Wallet</h2>
            <div>
                <label>Wallet Address:</label>
                {walletAddress ? (
                    <>
                        <p>{walletAddress}</p>
                        <button onClick={removeWallet}>Remove Wallet</button>
                    </>
                ) : (
                    <>
                        <p>None Linked</p>
                        {currentAccount ? (
                            <button onClick={linkWallet}>Link Wallet with Account</button>
                        ) : (
                            <span>Please connect your wallet from the main app to link.</span>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default Profile;