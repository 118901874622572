import { ContractAddress } from "../contracts/ContractVars";
import { Link } from 'react-router-dom';

function Nft(props) {
    return (          
            <div key="{props.tokenId}" className="collectionItem">            
                <img alt={`KITTY CAT #${props.tokenId}`} className="collectionImage" src={props.imagesrc} /><br />
            <a target="blank" href={`https://opensea.io/assets/${ContractAddress}/${props.tokenId}`}>View on opensea</a>
            <br />
                <Link to={{ pathname: "/viewcat/" + props.tokenId  }} >
                    <button type="button">
                        View Kitty Cat #{props.tokenId}
                    </button>
                </Link>
            </div>
        );
}

export default Nft;