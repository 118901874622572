import contract from '../contracts/KittyCatAbi.json';

export const ContractAddress = "0x8FeA3412a7C6032b4Dfa576136BE2D0233F92A7B";
export const ContractAbi = contract;
//export const ContractAbi = '[ "function balanceOf(address owner) view returns (uint256)", "function tokenOfOwnerByIndex(address owner, unit256 index)" ]';

//export const ImgRepos = "https://localhost:5001/nftjson/";
//export const TraitRepos = "https://localhost:5001/traitjson/";
//export const ApiRepos = "https://localhost:5001/api/";

export const ImgRepos = "https://imakittycatnft.com/nftjson/";
export const TraitRepos = "https://imakittycatnft.com/traitjson/";
export const ApiRepos = "https://imakittycatnft.com/api/";

export const ChainArr = [
    {
        id: '0x1', value: 'Ethereum Mainnet'
    },
    {
        id: '0x3', value: 'Ropsten Test'
    },
    {
        id: '0x4', value: 'Rinkeby Test'
    },
    {
        id: '0x5', value: 'Goerli Test'
    },
    {
        id: '0x2a', value: 'Kovan Test'
    },
    {
        id: '0x38', value: 'Binance Smart Chain'
    },
    {
        id: '0x89', value: 'Polygon Main'
    }    
];

