const MarketPlace = () => {

    return (
        <div className="marketplace">
            <br />
            <h1>Market Place</h1>
            <p>This page is currently being developed</p>
        </div>
    );
}

export default MarketPlace;