import React, { useState, useEffect, useCallback } from 'react';
import NFT from "./NFTComponent";
import { BrowserProvider, Contract } from "ethers";
import { ContractAddress, ContractAbi, ImgRepos } from "../contracts/ContractVars";

const ViewCats = ({ currentAccount, currentChain }) => {
    const [nfts, setNfts] = useState([]);
    const [loading, setLoading] = useState(false);

    const renderNfts = () => {
        return (
            <div className="renderCollection">
                {nfts.map((nft) => (
                    <div key={nft.id}>
                        <NFT                        
                            tokenId={nft.id}
                            imagesrc={nft.source}
                        />
                    </div>
                ))}
            </div>
        )
    }

    const NoNfts = () => {
        return (
            <div><br /><p>No Cats Found - please ensure you have connected your wallet and are on the Ethereum MainNet</p></div>
        );
    }

    const fetchCatsForAccount = useCallback(async (account) => {
        if (account && currentChain === 'Ethereum Mainnet') {
            setLoading(true);
            setNfts([]);

            const provider = new BrowserProvider(window.ethereum); // Updated to Web3Provider
            const signer = await provider.getSigner();
            const connectedContract = new Contract(ContractAddress, ContractAbi, signer);

            // Use the new method call convention for ethers v6
            const userBalance = await connectedContract.balanceOf(account);

            const fetchPromises = [];

            for (let i = 0; i < userBalance; i++) { // Convert BigNumber to number for the loop
                const tokenId = await connectedContract.tokenOfOwnerByIndex(account, i);
                const ipfsLink = ImgRepos + tokenId;
                const fetchData = fetch(ipfsLink).then(response => response.json().then(data => ({
                    id: tokenId.toString(),
                    source: data.image,
                    owner: account.toString()
                })));

                fetchPromises.push(fetchData);
            }

            const allData = await Promise.all(fetchPromises);

            setNfts(allData);
            setLoading(false);
        }
    }, [currentChain]);

    useEffect(() => {
        fetchCatsForAccount(currentAccount);
    }, [currentAccount, currentChain, fetchCatsForAccount]);

    // ... rest of your component ...

    return (
        <div className="viewcats">
            <h1>Kitty Cat NFTs</h1>
            {loading ? (
                <img src="./loading.gif" alt="Loading..." />
            ) : nfts && nfts.length ? (
                renderNfts()
            ) : (
                NoNfts()
            )}
        </div>
    )
}

export default ViewCats;