const CoinCollection = () => {

    return (
        <div className="coincollection">
            <br />
            <h1>Coin Collection</h1>
            <p>This page is currently being developed</p>
        </div>
    );
}

export default CoinCollection;