import React, { useState } from 'react';
import { ApiRepos } from '../contracts/ContractVars';

const Login = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    };

    const handleLogin = async () => {
        try {
            if (!isValidEmail(username)) {
                setError("Please enter a valid email address.");
                return;
            }

            if (!password) {
                setError("Password cannot be empty.");
                return;
            }

            const response = await fetch(`${ApiRepos}account/login`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Username: username,
                    Password: password
                })
            });

            if (!response.ok) {
                const data = await response.json();
                setError(data.error || "Login failed. Please check your credentials.");
                return;
            }

            const data = await response.json();
            //console.log("Login response data:", data);
            onLoginSuccess(data);

        } catch (error) {
            setError("Network error or unable to connect to the server. Please try again.");
        }
    };

    return (
        <div>
            <h2>Login</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div>
                <label>
                    Username (Email):
                    <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Password:
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </label>
            </div>
            <div>
                <button onClick={handleLogin}>Login</button>
                <button onClick={() => window.location.href = "/register"}>Register</button>
            </div>
        </div>
    );
};

export default Login;