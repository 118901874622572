import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ImgRepos } from "../contracts/ContractVars";
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Trait from "./TraitComponent";

const ViewCat = () => {
    const [loading2, setLoading2] = useState(true);
    const [currentNFT, setCurrentNFT] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [error, setError] = useState(null);
    const [furValueCode, setFurValueCode] = useState(null);

    const { id } = useParams();

    const getFurCode = (furValue) => {
        const furMap = {
            'Black & White': 'BW',
            'Gray': 'GR',
            'Black': 'BL',
            'White': 'WH',
            'Brown Tabby': 'BT',
            'Orange Tabby': 'OT',
            'Meme': 'ME',
            'Pink': 'PI',
            'Blue': 'BU',
            'Gold': 'GO',
            'Rainbow': 'RA'
        };

        return furMap[furValue] || null;
    }

    const renderTraits = () => {
        return (
            <div>
                {attributes.map((trait) => (
                    <Trait
                        key={trait.trait_type}
                        trait_type={trait.trait_type}
                        value={trait.value}
                        furCode={furValueCode}
                    ></Trait>))
                }
            </div>
        )
    }

    const GetNft = useCallback(async () => {
        setLoading2(true);
        const ipfsLink = ImgRepos + id;

        try {
            const response = await fetch(ipfsLink);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            setCurrentNFT(data);
            setAttributes(data.attributes);

            const furValue = data.attributes?.find(attr => attr.trait_type === "FUR")?.value;
            const determinedFurCode = getFurCode(furValue);
            setFurValueCode(determinedFurCode);

        } catch (err) {
            console.error("Error fetching data:", err);
            setError(err.message);
        } finally {
            setLoading2(false);
        }
    }, [id]);

    const TraitsAreGo = () => {
        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div>
                {loading2 ? <img src="../loading.gif" alt="Loading..." /> : renderTraits()}
            </div>
        );
    }

    useEffect(() => {
        GetNft();
    }, [GetNft]);

    return (
        <div className="viewcat">
            <br />
            <h1>ViewCat</h1>
            <div>
                <Row>
                    <Col md={1}></Col>
                    <Col md={4}>
                        <h4>Kitty Cat #{id}</h4>
                        <img alt={`KITTY CAT #${id}`} className="collectionImage" src={currentNFT.image} /><br />
                        <h5>Current Level: {currentNFT.current_level}</h5>
                        <h5>Current Attitude: {currentNFT.current_attitude}</h5>
                    </Col>
                    <Col md={6}>
                        <h4>Traits</h4>
                        {currentNFT ? TraitsAreGo() : ''}
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </div>
        </div>
    );
}

export default ViewCat;