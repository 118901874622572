import { TraitRepos } from "../contracts/ContractVars";
import { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';

function Trait(props) {
    const [currentTrait, setCurrentTrait] = useState({});
    const [loadingTrait, setLoadingTrait] = useState(true);

    const LoadTrait = useCallback(() => {
        setLoadingTrait(true);

        fetch(TraitRepos, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Property: props.trait_type,
                Title: props.value,
                FurCode: props.furCode
            })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {
            setCurrentTrait(data);
            setLoadingTrait(false);
        }).catch(error => {
            console.error("Error fetching trait data:", error);
            setLoadingTrait(false);
        });
    }, [props.trait_type, props.value, props.furCode]);

    useEffect(() => {
        LoadTrait();
    }, [LoadTrait]);

    return (
        <div>
            <Row className="traitrow">
                <Col><h5>{props.trait_type} : {props.value}</h5></Col>
                <Col>
                    {loadingTrait ? null :
                        (currentTrait.filename && currentTrait.filename !== 'traitnotfound.png' ?
                            <img className="traitImage" alt={`${props.trait_type} ${props.value}`} src={`https://futuretechnologyip.azurewebsites.net/traits/${currentTrait.filename}`} />
                            : ''
                        )
                    }
                </Col>
                <Col><b>Trait Effect on Cat?</b> {currentTrait ? currentTrait.effect : ''}</Col>
            </Row>
        </div>
    );
}

export default Trait;