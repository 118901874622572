import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRepos } from '../contracts/ContractVars';

const Register = ({ onRegisterSuccess }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    };

    const handleRegister = async () => {
        try {
            if (!isValidEmail(username)) {
                setError("Please enter a valid email address.");
                return;
            }

            if (!password) {
                setError("Password cannot be empty.");
                return;
            }

            const response = await fetch(`${ApiRepos}account/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Username: username,
                    Password: password
                })
            });

            const data = await response.json();

            // Check for OK status.
            if (response.ok) {
                console.log("Registration response data:", data);
                if (onRegisterSuccess) {
                    onRegisterSuccess(data);
                }
                // Redirect to home page
                navigate('/');
            } else {

                // If response was not OK, set the error message.
                setError(data.error || "Registration failed.");
            }

        } catch (error) {
            setError("An unexpected error occurred. Please try again.");
            console.error("Error during registration:", error);
        }
    };


    return (
        <div>
            <h2>Register</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div>
                <label>
                    Username (Email):
                    <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Password:
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </label>
            </div>
            <div>
                <button onClick={handleRegister}>Register</button>
            </div>
        </div>
    );
};

export default Register;
